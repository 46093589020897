.privacy-wrapper {
  max-width: 1080px;
  margin: 0 auto;
  align-content: center;
  text-align: left;
  font-family: Inter;
}

.privacy-wrapper p {
  font-size: 0.8em;
  line-height: 1.5em;
}

.privacy-wrapper li {
  padding: 0.2em 0;
}

.privacy-wrapper .italics {
  font-style: italic;
}

@media (max-width: 1100px) {
  .privacy-wrapper {
    margin: 0 5em;
  }
}

@media (max-width: 768px) {
  .privacy-wrapper {
    margin: 0 2em;
  }
}
