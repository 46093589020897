.slick-arrow-icon-left,
.slick-arrow-icon-right {
  position: absolute;
  display: block;
  cursor: pointer;
  background: transparent;
  color: black;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  transition: 0.5s ease-in-out;
  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: black;
    font-size: 40px;
    &::before {
      opacity: 1;
    }
  }
}

.slick-arrow-icon-left {
  left: -10px;
  [dir='rtl'] & {
    left: auto;
    right: -50px;
  }
}

.slick-arrow-icon-right {
  right: -10px;
  [dir='rtl'] & {
    left: -50px;
    right: auto;
  }
}

.slick-dots button:hover {
  overflow: hidden;
  background-color: transparent;
}
