* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  padding: 0;
  align-items: center;
  text-align: center;
}

.overall-wrapper {
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1365px;
  margin: 0 auto;
  height: 6.25em;
}

.logo {
  margin: 1rem;
}

.right-side-nav {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.right-side-nav .nav-links {
  display: flex;
  justify-content: space-evenly;
}

.right-side-nav .nav-links li {
  text-decoration: none;
  list-style: none;
  display: block;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.625em;
  cursor: pointer;
}

.right-side-nav .nav-links li:hover {
  color: #22ace7;
}

.right-side-nav .link-to-footer:hover {
  color: #22ace7 !important;
}

.right-side-nav .challenge {
  background-color: #88bed6;
  color: white;
}

.right-side-nav button {
  display: none;
  align-items: center;
  margin-right: 1.875em;
}

.right-side-nav .selector {
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
  line-height: 22px;
  border: none;
  cursor: pointer;
}

.footer {
  margin-top: auto;
}

.footer-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 1em;
}

.footer-content {
  width: 20em;

}

.footer-content p {
  font-size: 0.75em;
  font-weight: 500;
  line-height: 0.75em;
  font-family: Inter;
}

.footer-content a {
  text-decoration: none;
}

.footer-content a:visited {
  color: #22ace7;
}

.footer-content a:hover {
  color: #88bed6;
}

.footer-contact {
  display: flex;
  flex-direction: row;
  display: inline-block;
}


.footer-contact img {
  width: 1.875em;
  height: 1.875em;
  margin: 0.625em 0.625em;
}


@media only screen and (max-width: 768px) {
  .logo {
    height: 54;
    width: 100;
  }

  .right-side-nav .nav-links {
    display: none;
  }

  .right-side-nav button {
    display: flex;
    color: #67b4d6;
    background-color: white;
    border: 1px solid #67b4d6;
    cursor: pointer;
  }

  .right-side-nav #hidden {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-bottom: 2px solid rgb(211, 211, 211);
    top: 100px;
    right: 0;
    width: 100%;
    height: 17.5em;
  }

  .right-side-nav .nav-links li {
    padding: 0.5rem;
    display: block;
    font-size: 1em;
  }
}

.back-to-top {
  position: fixed;
  bottom: 0.3em;
  right: 0.9em;
  font-size: 3.125em;
  color: white;
  cursor: pointer;
  border-radius: 3.125em;
  border: none;
  box-shadow: 0 0.3125em 0.625em #ccc;
}
