.caption {
  max-width: 1250px;
  flex-flow: column;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  width: auto;
  padding-bottom: 30px;
  margin: 0 auto;
}

.caption .inner-caption {
  margin-left: 50px;
  display: flex;
  flex-flow: column;
}

.caption h1 {
  text-align: left;
  font-family: Bitter;
  font-size: 26px;
  margin-bottom: 40px;
  color: #3d3d3d;
}

.caption h2 {
  text-align: left;
  font-size: 26px;
  margin-bottom: 15px;
  color: #000000;
}

.caption h3 {
  text-align: left;
  font-size: 18px;
  color: #000000;
}

.caption h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin: 0;
  font-family: Inter;
  margin-bottom: 15px;
}

.caption .calendar-giveBetter-wrapper {
  max-width: 950px;
  display: flex;
  justify-content: space-between;
}

.caption .card-num {
  text-align: left;
  margin: 0;
}

/* card details */
.payment-detail-wrapper {
  text-align: left;
  margin-top: 20px;
}

.payment-detail-wrapper img {
  margin-right: 15px;
  vertical-align: middle;
}

.payment-detail-wrapper p {
  font-size: 15px;
}

@media only screen and (max-width: 1000px) {
  .caption .calendar-giveBetter-wrapper {
    flex-direction: column;
  }
}
