.stepper-content-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 45px;
  margin-bottom: 1em;
}

.selected:after {
  top: -24px;
  right: -24px;
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #67b4d6;
  border: 3px solid #4990b4;
}

.selected:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 8px;
  background: transparent;
  top: -23px;
  right: -25px;
  border: 2px solid white;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-55deg);
  -ms-transform: rotate(-55deg);
  transform: rotate(-55deg);
  z-index: 9;
}

/*Onboarding: Choose Campaign, 
Choose Challenge
GroupedChallenges*/
.campaign-wrapper {
  width: 300px;
  height: 200px;
  border-radius: 10px;
  border: 4px solid #4990b4;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 36px;
  position: relative;
  cursor: pointer;
}

.campaign-innerWrapper {
  padding-top: 28px;
  height: 190px;
}

.campaign-wrapper:hover .campaign-innerWrapper {
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
}

.campaign-country {
  font-family: Inter;
  font-weight: 600;
  font-size: 1.25em;
  text-align: center;
}

.campaign-logo-wrapper {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign-logo-wrapper img {
  width: 9.375em;
  height: 9.375em;
  border-radius: 50%;
  opacity: 0.8;
}

.choose-challenge-wrapper h2 {
  max-width: 795px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-style: normal;
  font-family: Inter;
  margin: 0 auto;
  margin-top: 50px;
}

.stepper-challenge-wrapper {
  width: 300px;
  height: 405px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 36px;
  border: 4px solid #4990b4;
  position: relative;
}

.challenge-innerWrapper {
  padding-top: 28px;
  height: 397px;
}

.stepper-challenge-wrapper:hover .challenge-innerWrapper {
  cursor: pointer;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
}

.challenge-name {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 1em;
  text-align: center;
}

.challenge-logo-wrapper {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.challenge-desc {
  background: #f3f3f3;
  padding: 0.6em 2em;
  margin: 0 1em;
  border-radius: 10px;
  text-align: left;
  font-size: 15px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 550;
  line-height: 20px;
}

.challenge-desc p {
  display: inline;
  margin-bottom: 0;
}

.challenge-desc a {
  text-decoration: none;
  color: #559cbb;
}

.challenge-desc a:hover {
  color: #2c718f;
}

.chooseCause-wrapper {
  margin-top: 60px;
}

.chooseCause-wrapper > h2 {
  max-width: 795px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-style: normal;
  font-family: Inter;
  margin: 0 auto;
  margin-top: 50px;
}

.chooseCause-wrapper > h2 span {
  color: #67b4d6;
}

.charity-wrapper {
  width: 300px;
  height: 405px;
  border-radius: 10px;
  border: 4px solid #4990b4;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 36px;
  position: relative;
}

.charity-wrapper .charity-innerWrapper {
  padding-top: 28px;
  height: 397px;
}

.charity-wrapper:hover .charity-innerWrapper {
  cursor: pointer;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
}

.charity-wrapper .charity-name {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  font-style: normal;
  line-height: 29px;
  color: black;
  margin-bottom: 12px;
  text-align: center;
}

.charity-wrapper .logo-wrapper {
  width: 117px;
  height: 117px;
}

.charity-wrapper .logo-wrapper img {
  width: 100%;
  height: 100%;
}

.Unseen-logo-style {
  height: 55px !important;
  width: 100px !important;
  margin-top: 25px !important;
}

.charity-wrapper .data-wrapper {
  display: flex;
  margin-left: 11px;
  margin-bottom: 27px;
}

.charity-wrapper .data-wrapper P {
  width: 125px;
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: start;
  margin-top: 20px;
  margin-bottom: 0;
}

.charity-wrapper .data-wrapper span {
  color: #67b4d6;
}

.charity-wrapper .text-wrapper {
  width: 235px;
  height: 115px;
  margin: 0 auto;
  background-color: #4990b4;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.charity-wrapper .text-wrapper p {
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-size: 16.5px;
  line-height: 22px;
  color: white;
  text-align: start;
  margin-left: 13px;
  margin-right: 5px;
}

.charity-wrapper .text-wrapper p > span {
  font-weight: 600;
}

.charity-wrapper .link-wrapper {
  margin-top: 28px;
  text-align: start;
  padding-left: 26px;
}

.charity-wrapper .link-wrapper > a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-family: Inter;
  line-height: 22px;
}

.charity-wrapper .link-wrapper a > img {
  height: 18px;
  width: 18px;
  vertical-align: baseline;
  margin-left: 7px;
}

/* give up give better component */
.giveUpGiveBetter-wrapper {
  width: 435px;
  display: flex;
  vertical-align: baseline;
  justify-content: space-evenly;
}

@media screen and (max-width: 510px) {
  .giveUpGiveBetter-wrapper {
    width: 95% !important;
    margin-left: -1em;
  }

  .giveUpGiveBetter-wrapper .giveBetter-wrapper img {
    width: 140px;
  }
}

@media screen and (max-width: 430px) {
  .giveUpGiveBetter-wrapper {
    width: 85%;
    margin-right: 0.5em;
  }

  .giveUpGiveBetter-wrapper .p1,
  .giveUpGiveBetter-wrapper .arrow-wrapper {
    font-size: 1em !important;
  }

  .giveUpGiveBetter-wrapper .p2 {
    font-size: 1.125em !important;
  }

  .giveUpGiveBetter-wrapper img {
    margin-top: 1em;
  }
}

@media screen and (max-width: 385px) {
  .giveUpGiveBetter-wrapper {
    width: 75%;
    margin-right: 0em;
    margin-left: 0.5%;
  }

  .giveUpGiveBetter-wrapper .p1,
  .giveUpGiveBetter-wrapper .arrow-wrapper {
    font-size: 0.9em !important;
  }

  .giveUpGiveBetter-wrapper .p2 {
    font-size: 1em !important;
  }
  .giveUpGiveBetter-wrapper p {
    font-size: 20px !important;
  }

  .giveUpGiveBetter-wrapper img {
    margin-top: 1.1em;
  }
}

.giveUpGiveBetter-wrapper .p1 {
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  line-height: 21px;
  font-weight: 500;
}

.giveUpGiveBetter-wrapper .p2 {
  font-family: Public Sans;
  font-size: 17px;
  font-style: normal;
  line-height: 28px;
  font-weight: 500;
  margin-top: 5px;
}

.giveUpGiveBetter-wrapper .arrow-wrapper {
  margin-top: 60px;
}

.giveUpGiveBetter-wrapper .arrow-wrapper img {
  display: flex;
  justify-content: center;
  max-width: 90px;
}

.giveUpGiveBetter-wrapper .arrow-wrapper > p {
  font-family: Public Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
  color: #88bed6;
}

.giveUpGiveBetter-wrapper .giveUp-wrapper img {
  height: 60px;
}

.giveUpGiveBetter-wrapper .giveBetter-wrapper img {
  height: 58px;
}
.image-wrapper {
  height: 70px;
}

/* Onboarding calculator
Base */
.calculator-wrapper {
  font-family: Inter;
  margin-top: 85px;
}

.calculator-header {
  font-size: 12px;
  padding: 0 1em;
  margin-bottom: 20px;
}

.calculator-body {
  text-align: left;
  margin: 2em 20em;
}

.calculator-radio-wrapper {
  display: flex;
  padding-right: 1em;
  margin-bottom: 1em;
}

.times-wrapper span,
.perCost-wrapper span {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}

.calculator-wrapper .radio {
  margin-bottom: 15px;
  cursor: pointer;
  align-items: center;
}

.calculator-wrapper .text-box {
  width: 100px;
  height: 33px;
  margin: 1em 0em;
  padding: 0.5em 0.5em;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
}

.calculator-wrapper input:invalid {
  border-color: red;
}

.calculator-bottom-box {
  background: #f5f5f5;
  padding: 0.5em 1em;
  margin: 2em 21em;
  border-radius: 10px;
}

.calculator-bottom-box p {
  align-items: center;
  padding-left: 2em;
  text-align: left;
}

.validation-show {
  font-size: 16px;
  font-family: Inter;
  color: red;
}

.validation-disappear {
  display: none;
}

@media (max-width: 980px) {
  .calculator-bottom-box {
    margin: 2em 10em;
  }

  .calculator-body {
    margin: 2em 10em;
  }

  .payment-info {
    flex-direction: column;
    justify-content: center !important;
  }

  .paymentInput {
    margin-bottom: 20px;
  }
}

@media (max-width: 700px) {
  .calculator-bottom-box {
    margin: 2em 5em;
  }

  .calculator-body {
    margin: 2em 5em;
  }
}

.login-wrapper Button,
.login-wrapper Button:hover {
  width: 150px;
  height: 51px;
  text-transform: none;
  font-variant-caps: normal;
  background: #67b4d6;
  border-radius: 10px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

/* payment and detail page */
.payment-wrapper {
  margin-top: 85px;
}
.paymentdetail-outer-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.payment-wrapper span {
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  line-height: 29px;
  font-weight: 600;
  font-family: Inter;
  margin-left: 10px;
  margin-bottom: 10px;
}

* :before {
  margin: 20px;
}

.payment-info {
  display: flex;
  flex-direction: row;
  max-width: 1080px;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 370px;
  margin-bottom: 10px;
}

.paymentInput {
  width: 550px;
  background-color: rgba(136, 190, 214, 0.4);
  border-radius: 10px;
  padding: 20px;
  padding-top: 0.5em;
}

.paymentDes-wrapper .paymentDes {
  max-width: 465px;
  font-family: Inter;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  text-align: start;
}

@media screen and (max-width: 500px) {
  .paymentDes-wrapper .paymentDes {
    margin-left: 5px;
    margin-right: 5px;
  }

  .giveUpGiveBetter-wrapper {
    margin: 0 auto;
  }
}

.chargeDes {
  width: 650px;
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  font-family: Inter;
  margin-top: 50px;
  margin: 0 auto;
  margin-top: 110px;
  margin-bottom: 40px;
}
