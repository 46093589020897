.shareContent {
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
}

.socialmedia {
  text-align: left;
  flex-direction: column;
  padding: 5%;
}

.sharelinks {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.share-button:nth-child(1) {
  margin-right: 1.5em;
}

.rec {
  display: flex;
  flex-direction: row;
  width: 621px;
  height: 49px;
  background: #67b4d6;
  box-sizing: border-box;
  border-radius: 10px;
}

.linkicon {
  text-align: left;
  margin-top: 1.2%;
  padding-left: 1.2%;
}

.rec p {
  display: flex;
  justify-content: center;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: white;
  margin: 10px 10px 10px 10px;
  padding: 0px 0px 0px 0px;
}

.copybutton {
  font-family: Public Sans !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center;
  color: white !important;
  text-transform: none !important;
}

.copybutton-outer {
  width: 73px;
  height: 33px;
  background: #4990b4;
  border-radius: 10px;
  margin-top: 8px;
  text-align: center;
}

@media (max-width: 1270px) {
  .shareContent-top {
    margin: 0em 2em;
  }
  .shareContent-middle {
    margin: 0em 2em;
  }
  .shareContent-last {
    margin: 0em 2em;
  }
  .sharelinks {
    margin: 0em 3em;
  }
  .rec {
    margin: 0em 3em;
  }
}

@media (max-width: 889px) {
  .shareContent-top {
    margin: 0em 1em;
  }
  .shareContent-middle {
    margin: 0em 1em;
  }
  .shareContent-last {
    margin: 0em 1em;
  }
  .sharelinks {
    margin: 0em 1.5em;
  }
  .rec {
    margin: 0em 1.5em;
  }
}

@media (max-width: 701px) {
  .rec {
    width: 550px;
  }
  .rec p {
    font-size: 22px;
  }
}

@media (max-width: 640px) {
  .rec {
    width: 500px;
  }
  .rec p {
    font-size: 18px;
  }
}

@media (max-width: 581px) {
  .rec {
    width: 470px;
  }
  .rec p {
    font-size: 16px;
  }
}

@media (max-width: 552px) {
  .rec {
    width: 420px;
  }
  .rec p {
    font-size: 14px;
  }
  .copybutton-outer {
    width: 60px;
    height: 33px;
  }
  .copybutton {
    font-size: 15px !important;
  }
}

@media (max-width: 493px) {
  .rec {
    width: 380px;
  }
  .rec p {
    font-size: 13px;
  }
}

@media (max-width: 453px) {
  .rec {
    width: 350px;
  }
  .rec p {
    font-size: 15px;
  }
  .copybutton {
    font-size: 13px;
  }
}

@media (max-width: 413px) {
  .rec {
    width: 300px;
  }
}
