.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  width: 30px;
  height: 30px;
  color: #67b4d6 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  width: 30px;
  height: 30px;
  color: #67b4d6 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  width: 30px !important;
  height: 30px !important;
}

.MuiStepLabel-labelContainer span {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
}

.label {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
}

.backbutton {
  width: 223px;
  height: 66px;
  font-family: Public Sans !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center;
  color: #000000 !important;
}

.backbutton:hover {
  background-color: rgb(245, 245, 245) !important;
}

.nextbutton {
  width: 400px;
  height: 66px;
  font-variant-caps: normal;
  background: #67b4d6 !important;
  border-radius: 10px !important;
  font-family: Public Sans !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: #ffffff !important;
}

.nextbutton:hover {
  background-color: #5a9ec6 !important;
}

.stepper-button {
  color: #67b4d6 !important;
}

.stepper-button:focus {
  background-color: white !important;
}

@media screen and (max-width: 750px) {
  .nextbutton {
    font-size: 18px !important;
  }
  .backbutton {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 650px) {
  .nextbutton img {
    display: none;
    visibility: hidden;
  }
  .backbutton img {
    display: none;
    visibility: hidden;
  }
  .nextbutton {
    font-size: 16px !important;
    height: auto;
  }
  .backbutton {
    font-size: 16px !important;
    height: auto;
  }
  .regis-backbutton {
    bottom: 64px;
    position: absolute;
  }
}

@media screen and (max-width: 470px) {
  .nextbutton {
    font-size: 14px !important;
  }
  .backbutton {
    font-size: 14px !important;
  }
}

.submit {
  width: 286px;
  height: 64px;
  background: #67b4d6 !important;
  border: 2px solid #88bed6 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  /* identical to box height */
  color: #ffffff !important;
}

.cancel {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #444444 !important;
}

.cancel:hover {
  background-color: rgb(245, 245, 245) !important;
}

.cancelcontainer {
  width: 225px;
  height: 64px;
  padding-top: 10px;
  border: 2px solid #444444;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

/* SubmitPage */
.socialmedia a {
  text-decoration: none;
}
