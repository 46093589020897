/*Common Styles*/
.whyGiveBetter-landing-wrapper {
  background-color: #88bed6;
  opacity: 0.9;
}

.landing-wrapper {
  background-color: #88bed6;
  opacity: 0.9;
}

.landing-background {
  max-width: 1080px;
  margin: 0 auto;
  padding: 1em;
}

.h1-section h1 {
  font-family: Bitter;
  font-weight: 600;
  font-size: 2.125em;
  color: white;
  margin-top: 0.5em;
  margin-bottom: 0px !important;
}

.h1-normal-section h1 {
  font-family: Bitter;
  font-weight: 600;
  font-size: 2.125em;
  padding-top: 0.5em;
  margin-top: 0px;
}

/*Title Section*/
.title-wrapper {
  font-family: Bitter;
  height: auto;
  color: #313131;
  max-width: 1106px;
  padding-bottom: 1em;
  padding-left: 0.3125em;
  padding-right: 0.3125em;
  margin: 0 auto;
}

fieldset {
  border: 0.3em dashed #67b4d6;
  border-radius: 1em;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.icon-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 1em;
}

.icon-wrapper h4 {
  font-family: Bitter;
  font-weight: 500;
  font-size: 1.5em;
  color: #313131;
  margin-top: 0.5em;
  margin-bottom: 0;
}

#coffees g {
  animation: fadeOut 3s forwards ease-out;
}

@keyframes fadeOut {
  20% {
    opacity: 1;
  }
  30%,
  100% {
    opacity: 0.2;
  }
}

#dollars g {
  animation: fadeIn 3s ease forwards;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.title-wrapper p {
  padding-top: 1.5em;
  text-align: center;
}

.charity-title-wrapper {
  padding: 0.5em;
  margin: 1em;
  display: block;
  align-items: center;
}

.charity-title-wrapper img {
  padding-right: 1em;
  padding-bottom: 1em;
}

.title-text {
  font-family: Bitter;
  font-weight: 500;
  font-size: 1.8em;
  margin: 0.55em;
  padding: 0.2em;
}

.title-text > span {
  font-weight: 700;
  position: relative;
}

.title-text > span .day-left {
  position: absolute;
  left: 158px;
  top: 9px;
}

.title-text img {
  vertical-align: middle;
}

.challenge-button {
  background-color: #67b4d6;
  font-family: Inter;
  color: white;
  border: none;
  font-weight: 600;
  line-height: 0;
  font-size: 1.7em;
  border-radius: 0.625em;
  padding: 1.2em;
  margin-bottom: 1em;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 480px) {
  .challenge-button {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 420px) {
  .challenge-button {
    font-size: 1.3em;
    margin-left: 1em;
  }
}

@media only screen and (max-width: 380px) {
  .challenge-button {
    font-size: 1.1em;
    margin-left: 1em;
  }
}

@media only screen and (max-width: 850px) {
  .title-wrapper {
    height: auto;
    margin: 1.5em 2.5em;
  }

  .icon-wrapper {
    display: flex;
    flex-direction: column;
  }

  .icon-wrapper div {
    padding-bottom: 0em;
  }

  .icon-wrapper .arrow {
    visibility: hidden;
  }

  .icon-wrapper .logo {
    height: 60px !important;
    width: 190px !important;
  }

  .componentOuter-wrapper > .content {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
  }

  .charity-title-wrapper {
    display: flex;
    flex-direction: column;
  }

  .charity-title-wrapper img:nth-child(1) {
    margin-bottom: 15px;
  }
}

/*So Why Give Better Section*/
.why-gb-wrapper {
  display: flex;
  flex-direction: row;
  color: white;
}

.why-gb-box {
  width: 31.25em;
}

.why-gb-box:nth-child(1) {
  margin-right: 3.125em;
}

.why-gb-h3 {
  display: flex;
  justify-content: center;
  font-family: Bitter;
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 500;
  margin: 0;
  padding-top: 0.2em;
}

.why-gb-content {
  font-family: Inter;
  font-size: 1.12em;
  line-height: 1.24em;
  font-weight: 400;
  text-align: left;
  padding: 0 0 0.5em 0;
  margin-top: 0.6em;
}

.why-gb-box .why-gb-icon {
  margin: 1em;
  width: 4.5em;
  height: 4.5em;
  margin-bottom: 0;
}

.support-local-icon {
  width: 4.5em;
  height: 4.5em;
  margin: 0 auto;
}

.why-gb-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  color: white;
  width: 30em;
}

@media (max-width: 620px) {
  .why-gb-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .why-gb-box {
    width: 90%;
    margin-right: 0 !important;
  }

  .why-gb-bottom {
    width: 90%;
  }
}

/*Concerned about money Section*/
.bottomBoxes {
  display: flex;
  gap: 1em;
  padding: 1em;
  justify-content: center;
}

.square {
  background: #ffffff;
  border: 3px solid black;
  box-sizing: border-box;
  border-radius: 0.625em;
  padding: 1em;
  width: 288px;
  margin-bottom: 2em;
  margin-right: 0.625em;
  margin-left: 0.625em;
}

.concerned-img {
  width: 1.875em;
  height: 1.875em;
}

.concerned-h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 1.25em;
  font-weight: 600;
}

.bottomBoxes p {
  font-family: Inter;
  text-align: left;
  font-weight: 500;
  font-size: 1.125em;
  color: #454545;
  line-height: 1.2em;
}

@media screen and (max-width: 768px) {
  .bottomBoxes {
    flex-direction: column;
    padding: 1.875em;
    margin-right: 1em;
  }

  .square {
    width: 100%;
    margin-bottom: 0.625em;
  }
}

/*Who is GB Section*/
.who-gb-wrapper {
  display: flex;
  justify-content: space-evenly;
}

.who-gb-box {
  width: 52.125em;
  text-align: left;
  color: white;
  font-family: Inter;
  font-size: 1.25em;
  line-height: 1.8125em;
  text-decoration: none;
  margin-bottom: 1.25em;
}

@media screen and (max-width: 768px) {
  .who-gb-box {
    width: 80%;
  }
}

/*How does the challenge work Secion*/
.link-to-concerned {
  display: flex;
  font-family: Inter;
  font-size: 0.875em;
  line-height: 1em;
  padding-top: 1em;
  color: #ffffff;
}

.outer {
  max-width: 1360px;
  margin: 0 auto;
  margin-top: 50px;
}

.howitwork {
  padding: 1em;
}

.head-wrapper {
  display: flex;
  align-items: center;
}

.first-part-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.875em;
}

.second-part-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 1026px) {
  .second-part-wrapper {
    display: flex;
    flex-direction: column-reverse !important;
  }
}

@media screen and (max-width: 1100px) {
  .rect {
    margin-top: 1em;
  }
}

@media screen and (max-width: 1011px) {
  .second-part-wrapper {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px !important;
    margin-left: 0;
  }
}

.third-part-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 50px;
}

.circle {
  width: 3.125em;
  height: 3.125em;
  background: #ffffff;
  border-radius: 50%;
  font-family: Bitter;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 2.6875em;
  color: #88bed6;
  padding-top: 0.3125em;
  margin-left: 3.5em;
  margin-right: 1em;
}

.title {
  font-family: Bitter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6em;
  line-height: 1em;
  color: #ffffff;
  margin-top: 3em;
  margin-bottom: 2.5em;
}

.paragraph {
  font-family: Inter;
  font-size: 1.125em;
  line-height: 1.4375em;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  margin-left: 3em;
  padding-right: 2em;
  padding-bottom: 2em;
  margin-top: 0;
}

.rect {
  max-width: 32.5em;
  background: #88bed6;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0.625em;
  margin-bottom: 1.875em;
}

#rectangle2 {
  margin-left: 1.6em;
}

.challenge-display {
  width: 28.125em;
  min-height: 18.75em;
  margin-left: 0.625em;
}

/*Potential Calculator*/
.calculator-slider {
  width: 35%;
  height: 9.275em;
  overflow: hidden;
  font-family: Inter;
  text-align: left;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 1.3em;
  margin-left: 2em;
}

@media screen and (max-width: 1100px) {
  .calculator-slider {
    width: 43%;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
  }
}

@media screen and (max-width: 890px) {
  .calculator-slider {
    width: 65%;
  }

  #rectangle2 {
    margin-left: 0;
  }
}

@media screen and (max-width: 680px) {
  .calculator-slider {
    width: 80%;
  }
}

.calculator-slides {
  display: flex;
  position: relative;
  top: 0;
  right: 0;
  animation: slideMove ease-in-out 20s infinite;
}

.calculator-slides:hover {
  animation-play-state: paused;
}

.slide-calculator {
  width: 100%;
  flex-shrink: 0;
}

.slide-calculator img {
  position: relative;
}

hr.solid {
  border-top: 4px solid #000000;
}

@keyframes slideMove {
  0% {
    right: 0;
  }
  20% {
    right: 0;
  }
  25% {
    right: 100%;
  }
  45% {
    right: 100%;
  }
  50% {
    right: 200%;
  }
  70% {
    right: 200%;
  }
  75% {
    right: 300%;
  }
  95% {
    right: 300%;
  }
  100% {
    right: 0;
  }
}

.slide {
  max-width: 28.125em;
  color: #000000;
  display: flex;
  flex-direction: column;
  margin-left: 0.625em;
}

@media screen and (max-width: 500px) {
  .slide {
    max-width: 85%;
  }
}

.slide p {
  font-family: Inter;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5625em;
  margin-top: 0;
  margin-bottom: 10px;
}

.slide-img {
  margin: 0 auto;
  height: 6.25em;
  max-width: 40%;
  padding-right: 0.625em;
  padding-left: 0.625em;
}

/* Challenge Switcher */
.challenge-display {
  width: 450px;
  min-height: 300px;
  margin-left: 10px;
}

.challengeSwitcher-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 1.8125em;
  margin: 0;
}

.challengeSwitcher-des {
  font-family: Inter;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 1.25em;
  color: rgba(136, 190, 214, 1);
  margin: 0;
  padding-bottom: 0.2em;
}

.challengeSwitcher-wrapper .challenge-wrapper {
  width: 18.125em;
  background-color: #ebebeb;
  margin: 0 auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
  margin-bottom: 0.75em;
  margin-top: 0.5625em;
  border: 0.0625em dashed rgba(204, 204, 204, 1);
  box-shadow: none;
  border-radius: 0;
}

.challengeSwitcher-wrapper .challenge-wrapper li {
  width: 6em;
  height: 6em;
  border: 0.0625em dashed rgba(204, 204, 204, 1);
  padding-top: 0.9375em;
  cursor: pointer;
}

.challengeSwitcher-wrapper .challenge-wrapper li:nth-child(3) {
  padding-left: 12px;
  padding-right: 12px;
}

.challengeSwitcher-wrapper .challenge-wrapper .challenge-selected {
  border: 0.1875em solid;
  filter: invert(97%) sepia(7%) saturate(6105%) hue-rotate(170deg) brightness(87%) contrast(92%);
}

.challengeSwitcher-wrapper .challenge-wrapper .iconThree {
  width: 38%;
  height: 75%;
}

.challengeSwitcher-wrapper .challenge-wrapper img {
  width: 73%;
  height: 75%;
  filter: brightness(20%) saturate(100%) invert(20%);
}

.challengeSwitcher-wrapper .challengeSwitcher-pledge {
  font-family: Inter;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 1.25em;
  margin: 0;
}

/* Impact across NZ */
.outermap {
  vertical-align: middle;
  max-width: 55.625em;
  margin: 0 auto;
}

.impact-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  font-family: Inter;
  font-weight: 600;
  font-size: 1rem;
  padding: 1em;
}

.impact-section img {
  background: #3ea5d1;
  border-radius: 0.625em;
  margin: 0.5rem;
}

.impact-quote {
  position: relative;
}

.impact-quote span {
  position: absolute;
  text-align: left;
  color: white;
  right: 2.2rem;
  top: 2.7rem;
  width: 18rem;
}

@media screen and (max-width: 1053px) {
  .impact-section img {
    height: 95%;
    width: 95%;
  }

  .impact-quote span {
    font-size: 0.9em;
    left: 1.6em;
    top: 1.7rem;
    width: 15rem;
  }
}

@media screen and (max-width: 891px) {
  .impact-section {
    flex-direction: column;
  }

  .impact-section {
    margin-left: 0.5rem;
  }

  .impact-quote {
    position: relative;
  }

  .impact-quote span {
    left: 2.1em;
    top: 2.8rem;
    width: 19rem;
    font-family: Inter;
    font-weight: 600;
    font-size: 1rem;
  }
}

@media screen and (max-width: 390px) {
  .impact-quote span {
    left: 2.1em;
    top: 2.1rem;
    width: 17rem;
    font-family: Inter;
    font-weight: 600;
    font-size: 1rem;
  }
}

.outermap .hidden-data {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}

.mapanddots {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mapanddots .nz-map {
  max-width: 49.375em;
  position: relative;
}

.mapanddots .uk-map {
  max-width: 49.375em;
  position: relative;
}

.toggle-wrapper {
  display: flex;
  justify-content: center;
}

.namecardouter {
  width: 580px;
  display: flex;
  flex-direction: row;
  margin-left: 38px;
  position: relative;
}

.NZnamecard2 {
  position: absolute;
  right: -55px;
  top: -115px;
  animation: 1.5s ease 0s 1 normal none running AniOpaOpen;
}

.hideNZnamecard2 {
  display: none;
}

.UKnamecard {
  position: absolute;
  top: -115px;
  left: -535px;
  z-index: 1;
  animation: 1.5s ease 0s 1 normal none running AniOpaOpen;
}

.ModalHoverChild > * {
  visibility: visible;
}
@keyframes AniOpaOpen {
  0% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
}

.dots-wrapper li {
  animation: 1s ease 0s 1 normal none running AniOpaOpen;
  list-style: none;
}

.UKdots-wrapper li {
  animation: 1s ease 0s 1 normal none running AniOpaOpen;
  list-style: none;
}

.dots-wrapper li:nth-child(1) {
  position: absolute;
  left: 31.25em;
  top: 8.75em;
}

.dots-wrapper li:nth-child(2) {
  position: absolute;
  left: 38.75em;
  top: 14.6875em;
}

.dots-wrapper li:nth-child(3) {
  position: absolute;
  left: 27.8125em;
  top: 22.1875em;
}
.dots-wrapper li:nth-child(4) {
  position: absolute;
  left: 33.125em;
  top: 23.75em;
}
.dots-wrapper li:nth-child(5) {
  position: absolute;
  left: 11.875em;
  top: 33.75em;
}
.dots-wrapper li:nth-child(6) {
  position: absolute;
  left: 20em;
  top: 38.125em;
}
.dots-wrapper li:nth-child(7) {
  position: absolute;
  left: 21.25em;
  top: 27.1875em;
}

.UKdots-wrapper li:nth-child(1) {
  position: absolute;
  left: 32.7em;
  top: 47.5em;
}

.namecard-wrapper {
  max-width: 445px;
  background: #e6f4fb;
  border: 3px solid #7ab5cf;
  box-sizing: border-box;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
}

.profiletext-wrapper {
  display: flex;
  margin-left: 0.83125em;
  margin-top: 1.3125em;
}

.profiletext-wrapper .profile-wrapper {
  height: 5em;
  width: 5em;
  border-radius: 50% !important;
  border: 0.1875em solid #7ab5cf;
  position: absolute;
  left: -1.75em;
  top: -1.875em;
  overflow: hidden;
}

.profiletext-wrapper .profile-wrapper img {
  height: 6.25em;
  width: 6.25em;
  position: absolute;
  left: -0.625em;
  top: -0.625em;
}

.profiletext-wrapper .textbox-wrapper p {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.375em;
  text-align: left;
  margin-left: 10%;
  margin-top: auto;
}

.blurarrowright {
  display: inline-block;
  vertical-align: top;
  width: 0;
  height: 0;
  border-top: 9.375em solid transparent;
  border-bottom: 7.5em solid transparent;
  border-left: 3.75em solid #88bed6;
}

.toHover1 {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #7ab5cf;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  visibility: visible;
}

.toHover1 div {
  visibility: hidden;
}

.toHover1:hover {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #88bed6;
  border: 2px solid #88bed6;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
}

.ModalHoverCont {
  transform: translate(-535px, -185px) !important;
}

@media only screen and (min-width: 850px) and (max-width: 1450px) {
  .ModalHoverCont {
    transform: rotateZ(90deg) translate(-780px, 25px) !important;
  }

  .namecard-wrapper {
    display: flex;
    justify-content: space-evenly;
    max-width: 440px;
    border-radius: none !important;
    max-height: 300px !important;
    transform: rotateZ(0deg) translateY(0px) translateX(0px) !important;
    border-radius: 10px 0px 0px 10px;
  }

  .blurarrowright {
    border-top: 150px solid transparent;
    border-bottom: 150px solid transparent;
    border-left: 60px solid #88bed6;
  }

  .NZnamecard1 {
    transform: rotateZ(270deg) translateY(595px) translateX(-520px) !important;
  }

  .NZnamecard2 {
    transform: rotateZ(0deg) translateY(0px) translateX(5px) !important;
  }

  .UKnamecard {
    transform: rotateZ(0deg) translateY(0px) translateX(5px) !important;
  }
}

@media only screen and (max-width: 850px) {
  .nz-map .dots-wrapper {
    display: none;
  }

  .uk-map .UKdots-wrapper {
    display: none;
  }

  .NZnamecard2,
  .UKnamecard {
    display: block !important;
    position: relative !important;
    top: 0;
    left: 0;
  }

  .outermap .hidden-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 850px;
  }

  .outermap .hidden-data {
    max-width: 100% !important;
  }

  .outermap .hidden-data .namecardouter {
    max-width: 450px;
    margin-left: 0;
  }

  .mapanddots .nz-map,
  .mapanddots .uk-map,
  .blurarrowright {
    display: none;
  }

  .namecard-wrapper {
    max-width: 440px;
    border-radius: 10px 10px 10px 10px;
    margin: 25px 0 25px 0;
    position: relative;
  }
}

@media screen and (max-width: 510px) {
  .namecard-wrapper,
  .bottom-wrapper {
    width: 80%;
    margin-left: 10%;
  }

  .profile-wrapper {
    width: 4.5em !important;
    height: 4.5em !important;
  }
}

@media screen and (max-width: 430px) {
  .namecard-wrapper,
  .bottom-wrapper {
    max-width: 75%;
    margin-left: 15%;
  }

  .profiletext-wrapper .textbox-wrapper p {
    font-size: 1em;
  }

  .profile-wrapper {
    width: 4em !important;
    height: 4em !important;
  }
}

@media screen and (max-width: 385px) {
  .namecard-wrapper,
  .bottom-wrapper {
    max-width: 78%;
    margin-left: 10%;
  }

  .profiletext-wrapper .textbox-wrapper p {
    font-size: 1em;
  }

  .profile-wrapper {
    width: 4em !important;
    height: 4em !important;
  }
}

html {
  scroll-behavior: smooth;
}

/*404 Page*/
.error_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3em;
}

.error_page h1 {
  font-family: Inter;
  font-weight: 600;
  font-size: 2em;
  text-align: center;
  color: #272626;
  margin-bottom: 0.5em;
}

.error_page img {
  border-radius: 1.5em;
  box-shadow: 0.5em 0.5em 0.5em #b4afaf;
  width: 24%;
  height: auto;
}

@media screen and (max-width: 1100px) {
  .error_page img {
    width: 33%;
  }
}

@media screen and (max-width: 880px) {
  .error_page img {
    width: 43%;
  }
}

@media screen and (max-width: 730px) {
  .error_page img {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .error_page img {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .error_page {
    margin-bottom: 4.5em;
  }

  .error_page img {
    width: 70%;
  }
}

@media screen and (max-width: 430px) {
  .error_page {
    margin-bottom: 5em;
  }

  .error_page img {
    width: 80%;
  }
}
