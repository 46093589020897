.admin-wrapper {
    max-width: 1080px;
    margin: 0 auto;
    padding: 1em;
    height: calc(100vh - 250px);
}

.admin-wrapper h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-family: Inter;
    text-align: left;
    margin-left: 1em;
}

.admin-menu {
    list-style: none;
    text-align: left;
    display: flex;
    flex-direction: column;
    font-family: Inter;
}

.admin-menu > li {
    margin-bottom: 0.5rem;
}

.admin-link:link {
    color: #74c2e9;
    text-decoration: none;
}

.admin-link:visited {
    text-decoration: none;
    color: #74c2e9;
}

.admin-link:hover {
    color: #00a8ff;
    cursor: pointer;
}

.admin-table-wrapper {
    max-width: 1080px;
    margin: 0 auto;
    padding-bottom: 2em;
}