.about-section {
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  padding-bottom: 2em;
}

.about-headers h2 {
  font-size: 1.7em;
  line-height: 0.7em;
  margin: 0;
  padding: 0.3em 0.3em;
}

.about-top-content {
  background: #88bed6;
  border-radius: 10px;
  margin: 15px 20px 30px 20px;
  padding: 0.5em 1.5em;
  text-align: left;
}

.about-top-content p {
  display: flex;
  color: white;
  font-size: 1em;
  line-height: 1.3em;
}

.about-body-normal {
  margin: 15px 20px 15px 20px;
  padding: 0em 1.5em;
  text-align: left;
}

.about-body-normal p {
  font-size: 1em;
  line-height: 1.3em;
  text-align: left;
}

.about-body-normal li {
  font-size: 1em;
  line-height: 1.3em;
  padding: 0.2em 0;
  margin: 0 1em;
}

.emphasize {
  font-weight: bold;
}

.about-body-indent {
  margin: 0px 250px 0px 250px;
  padding: 0em 1em;
  text-align: left;
}

.about-body-question {
  display: flex;
  font-size: 1em;
  line-height: 1.3em;
  font-weight: 600;
}

.team-selfies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

.team-selfies > div {
  width: 170px;
  margin: 0 20px;
}

.team-selfies > div .name {
  font-weight: 700;
}

.selfie {
  border-radius: 20px;
  margin: 0;
  padding: 0;
}

.normalSizeSelfie {
  height: 133px;
  width: 133px;
}

.tallSizeSelfie {
  height: 143px;
  width: 128px;
}

@media (max-width: 1149px) {
  .about-top-content {
    margin: 1em 2em;
  }

  .about-body-normal {
    margin: 0em 2em;
  }

  .about-body-indent {
    margin: 0em 2em;
    padding: 0em 10em;
  }
}

@media (max-width: 974px) {
  .about-body-indent {
    margin: 0em 2em;
    padding: 0em 5em;
  }
}

@media (max-width: 768px) {
  .about-body-indent {
    margin: 0em 2em;
    padding: 0em 4em;
  }
}
