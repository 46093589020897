.calendar-wrapper {
  width: 390px;
}

.calendar {
  list-style: none;
  padding-left: 0;
}

.calendar li {
  margin-bottom: 11px;
}

.calendar li .week {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  vertical-align: middle;
  margin-right: 25px;
}

.calendar li .date {
  display: inline-block;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 11px;
}

.calendar li .passed-date {
  background-color: #e8e8e8;
}

.calendar li .rest-date {
  border: 2px solid #e8e8e8;
  background-color: white;
}

.calendar li .passed-payment {
  background-color: #cacaca;
}

.calendar li .rest-payment {
  background-color: #67b4d6;
}

.calendar li .payment-date {
  font-family: Public Sans;
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  padding-top: 5px;
}

.calendar > .highlight {
  border: 3px solid #88bed6 !important;
  border-radius: 10px;
}

Button:hover {
  background-color: #5a9ec6;
}
