/*Register Page*/
.terms-wrapper {
  display: flex;
  font-size: 14px;
  align-items: center;
}

input[type="checkbox"].regCheckbox {
  height: 25px;
  width: 25px;
  margin-right: 1em;
  margin-top: 0.5em;
}

.privacy-link {
  margin: 0;
  margin-top: 0.5em;
  margin-bottom: 10px !important;
}

.registerbutton {
  padding-top: 20px;
  margin: 0 auto;
}

.registerbutton Button:hover {
  background: #5a9ec6 !important;
}

.login-register-button {
  font-family: Inter;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  line-height: 1.2em;
  font-size: 0.85em;
}

.loader {
  width: 70px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 4.25em;
  font-size: 23px !important;
  font-weight: 600;
}

.address-input {
  border: 1px solid #080808;
  border-radius: 1em;
  margin-top: 1em;
  padding: 1em;
  padding-top: 0.5em;
}

.address-input select {
  height: 50px;
  border-radius: 10px;
  width: 100%;
  border: 2px solid #080808;
  margin-top: 1em;
}

.name-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

@media screen and (max-width: 500px) {
  .drop-down-button {
    width: 200px;
  }

  .nextbutton {
    width: 300px;
  }
}

/* Forms */
.Form-wrapper {
  display: flex;
  justify-content: center;
}

.Form {
  justify-content: end;
  flex-direction: column;
  width: 453px;
}

.Form p {
  font-family: Inter;
  font-size: 0.875em;
  color: red;
}

.Form h1 {
  font-family: Bitter;
  font-weight: 600;
  font-size: 1.75em;
  margin-bottom: 0.2em;
  text-align: center;
}

.Form label {
  font-family: Inter;
  font-size: 1em;
  text-align: left;
}

.Form .from-label {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 13px;
  text-align: left;
}

.Form input {
  height: 40px;
  width: 100%;
  border-radius: 0.75em;
  padding-left: 1em;
  margin-top: 1em;
}

.button-wrapper {
  display: flex;
  flex-flow: column;
}

.button-wrapper Button {
  width: 50%;
  margin: 0 auto;
  text-transform: none;
  background: #67b4d6;
  border-radius: 10px;
  font-family: Inter;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.button-wrapper Button:hover {
  background: #5a9ec6;
}

.Form span {
  color: green;
  font-family: Inter;
  font-size: 1em;
}

@media screen and (max-width: 480px) {
  .Form {
    width: 90%;
  }

  .button-wrapper Button {
    font-size: 1em;
  }
}
